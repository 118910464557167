import { About } from "../About.js";
import { Skills } from "../Skills";

const AboutPage = () => {
  return (
    <>
      <About />
      <Skills />
    </>
  );
};

export default AboutPage;
