import { YarnProject } from "../YarnProject.js";

const YarnProjectPage = () => {
  return (
    <>
      <YarnProject />
    </>
  );
};

export default YarnProjectPage;
