import { ChristmasProject } from "../ChristmasProject.js";

const ChristmasProjectPage = () => {
  return (
    <>
      <ChristmasProject />
    </>
  );
};

export default ChristmasProjectPage;
